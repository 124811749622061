<template>
<div class="App">
      <div class="vertical-center">
        <div class="inner-block" style="margin: 0px 120%">
           <div class="vue-tempalte">
                <h3>Change Your Password</h3>
                <div class="form-group">
                    <label>New Password</label>
                    <input type="password" class="form-control form-control-lg" v-model="formChange.password" />
                </div>
                <div class="form-group">
                    <label>Confirm New Password</label>
                    <input type="password" class="form-control form-control-lg" v-model="formChange.confirm_password" />
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn btn-primary btn-lg btn-block" @click="submit">Submit</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-danger btn-lg btn-block" @click="toDashboard">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
   
</template>

<script>
    import axios from 'axios';
    export default {
        components: {},
        data() {
            return {
                formChange : {
                    password : '',
                    confirm_password : ''    
                }
            }
        },
        methods: {
            submit(){
                let vm = this;
                if (vm.formChange.password != vm.formChange.confirm_password) {
                    alert('Password and Confirm Password not equal!');
                    return false;
                }
                axios.post(this.$root.baseUrlApi+'functions/auth/change_password.php', this.formChange,
                    {
                        headers : {'Authorization' : vm.$parent.accessToken},
                    }).then(function (response) {
                        console.log(response.data.error);
                        if (response.data.error == false) {
                            alert("Notification : "+response.data.message);
                        } else {
                            alert("Error Occured : "+response.data.message);
                        }

                        vm.$router.push({path : '/' });
                    }).catch(function (error) {
                        console.log(error);
                        if (!error.response) {
                            alert("Please check your connection and try again");
                        } else {
                            if (error.response.data.message) {
                                alert(error.response.data.message);
                            } else {
                                alert("error occured in server");
                            }
                        }
                });
            },
            toDashboard() {
                this.$router.push({path : '/' });
            }
        }
    }
</script>